.slider-item {
  width: 165px;
}
.slider-item:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: 0.3s;
}
:global(.lazy-slider-item-img) {
  border-radius: 10px;
}

@media screen and (max-width: 500px) {
  .slider-item {
    width: auto;
  }
}
