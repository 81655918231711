.addonOption {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 18px;
}

.addonDropdown {
  position: absolute;
  top: 4rem;
  left: 0;
  padding: 10px 0;
  box-shadow: 1px 8px 10px -6px #000;
  border-radius: 4px;
  z-index: 2;
  background-color: white;
}

.dropDownList {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 200px;
  overflow: auto;
}

.dropDownList span {
  display: flex;
  justify-content: space-between;
  padding: 1px 2px;
  background-color: white;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
}

.dropDownList > span[data-selected='true'] {
  background-color: var(--primary-color);
}

.dropDownList > span:hover {
  background-color: var(--primary-color);
}

.dropDownList::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  position: absolute;
}

.dropDownList::-webkit-scrollbar-track {
  background: transparent;
}

.dropDownList::-webkit-scrollbar-thumb {
  background: #282828;
  border: 1px solid #282828;
  border-radius: 4px;
}
