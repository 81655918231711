.episode-item-wrapper {
  height: auto;
  width: auto;
  min-width: 220px;
  max-width: 380px;
  margin: 0 25px 35px 0;
  flex: 0 1 calc(1 / 3 * 100% - 25px); /* flex-basis: 33.33%, margin-right 25px */
  color: #000;
  cursor: pointer;
}

.episode-thumbnail {
  width: 100%;
  max-height: 220px;
  object-fit: cover;
  border-radius: 10px;
}

.episode-title {
  font-size: 18px;
  line-height: 25px;
}

.episode-duration {
  margin: 0 0 10px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.episode-description {
  font-weight: normal;
  font-size: 12.5px;
  line-height: 22px;
}

@media screen and (max-width: 1024px) {
  .episode-item-wrapper {
    margin: 0 15px 25px 0;
    flex: 0 1 calc(1 / 3 * 100% - 15px);
  }
}

@media screen and (max-width: 802px) {
  .episode-item-wrapper {
    min-width: 200px;
    flex: 0 1 calc(1 / 2 * 100% - 15px);
  }
}

@media screen and (max-width: 486px) {
  .episode-item-wrapper {
    flex: 0 1 calc(100% - 15px);
    margin: 0 15px 15px 0;
  }
  .episode-thumbnail {
    width: 185px;
    max-height: 120px;
    object-fit: fill;
    border-radius: 10px;
  }
  .episode-title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }
  .episode-duration {
    margin: 0 0 10px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  .episode-description {
    margin: 10px 0 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.description-wrapper {
  margin-left: 10px;
  max-width: 135px;
}
