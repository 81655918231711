.collection-item {
  min-width: 107px;
  position: relative;
  float: left;
  display: block;
  cursor: pointer;
  width: 15%;
  margin: 15px auto;
}

.empty {
  height: 0;
}

.collection-item a {
  width: 100%;
  height: 100%;
  display: block;
}

.item-image {
  padding-bottom: 132.5%;
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.item-image:hover {
  opacity: 0.7;
  transition: 0.3s all;
}

@media screen and (max-width: 1024px) {
  .collection-item {
    max-width: 184px;
    width: 25%;
  }
}

@media screen and (max-width: 768px) {
  .collection-item {
    width: 30%;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .collection-item {
    width: 30%;
    margin: 10px auto auto auto;
  }
}
