.footer-wrapper {
  width: 100%;
  margin: 50px auto 0;
  box-sizing: border-box;
  background-color: #f6cb52;
  text-align: center;
}

.layout {
  width: 100%;

  display: flex;
  gap: 16px;

  justify-content: space-between;
  align-items: center;
}
.grow1 {
  flex-grow: 1;
}

.logo {
  width: 121px;
  height: 35px;
}

.appVersion {
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  font-size: 12px;
}

.copyrightEmail {
  margin-top: 30px;
  width: 100%;
  justify-content: center;
}

.footerContent {
  padding: 0 15px;
  position: relative;
  margin: 0 auto;
}

.footer {
  padding: 30px 30px 40px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.footer ul {
  width: 25%;
  padding-left: 20px;
  font-size: 14px;
  box-sizing: border-box;
}

.footer ul:first-child {
  padding-left: 0;
}

.footer li {
  margin-bottom: 10px;
}

.head {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}

.version {
  font-size: 10px;
  line-height: 14px;
  position: absolute;
  bottom: 30px;
  right: 15px;
}

.iconsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsWrapper a {
  margin-right: 50px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.iconsWrapper a:last-child {
  margin-right: 0;
}

@media screen and (max-width: 500px) {
  .footer {
    padding: 30px 0 40px;
  }

  .footer ul {
    width: 50%;
  }

  .version {
    bottom: 20px;
  }

  .footer ul:nth-child(odd) {
    padding-left: 0;
  }

  .footer ul:nth-child(even) {
    padding-left: 20px;
  }

  .grow1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .grow1 .menu {
    text-align: left;
  }

  .footer-wrapper {
    padding-bottom: 40px;
  }
  .iconsWrapper a {
    margin-right: 30px;
  }
  .layout {
    margin-top: 30px;
  }
}

@media screen and (max-width: 300px) {
  .layout {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
