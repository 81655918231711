.profile-wrapper {
  display: flex;
  flex-direction: column;
  width: 260px;
  background: #dcdcdc;
  border-radius: 6px;
  padding: 25px;
  position: absolute;
  right: 0.3rem;
  top: 65px;
  z-index: 2;
}
.profile-wrapper:after,
.profile-wrapper:before {
  bottom: 100%;
  border: 1px solid #dcdcdc;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.profile-wrapper:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #dcdcdc;
  border-width: 19px;
  left: 90%;
  margin-left: -19px;
}
.profile-wrapper:before {
  border-color: rgba(113, 158, 206, 0);
  border-width: 20px;
  left: 90%;
  margin-left: -20px;
}
.profile-wrapper p {
  font-size: 16px;
  line-height: 22px;
  word-break: break-all;
  color: #000;
}
.avatarBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  align-self: center;
  padding: 4px;
  border: 3.3px solid rgba(84, 84, 84, 0.5);
  border-radius: 50%;
  user-select: none;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 87px;
  height: 87px;
  background: #f49832;
  border-radius: 60px;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}
.premiumIcon {
  position: absolute;
  bottom: -10px;
}
.profile-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-detail > h3 {
  margin-bottom: 5px;
  text-align: center;
}
.profile-email {
  margin: 0 0 1rem 0;
}
.profile-settings p:hover {
  cursor: pointer;
  color: #f49832;
}
.logout {
  cursor: pointer;
}
.logout:hover {
  color: #f49832;
}

@media screen and (max-width: 320px) {
  .profile-wrapper {
    background: rgba(0, 0, 0, 0.8);
    width: calc(100% - 40px);
    padding: 15px 20px;
  }

  .logout {
    margin-bottom: 0;
  }
}
