.button {
  position: relative;
  border: none;
  color: black;
  font-size: 12px;
  border-radius: 6px;
  line-height: 19px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 13px 0;
  padding: 8px 15px;
  justify-content: center;
  overflow: hidden;
}

.button[data-disabled='true'] {
  cursor: default !important;
}

/* .icon {
  padding: 1rem 2rem;
  justify-content: flex-start;
} */
.has-icon {
  padding: 13px 70px;
  justify-content: flex-start;
}
.outlined {
  border: 1px solid #f49832;
  background-color: transparent !important;
}

.text {
  padding: 8px 0;
  background-color: transparent !important;
}

.default-shape {
  border-radius: 6px;
}

.round-shape {
  border-radius: 50px;
}

.small {
  border-radius: 5px;
  padding: 6px 0;
}

.large {
  margin-right: 2rem;
  bottom: 1rem;
}

.button > div {
  margin-right: 0.7rem;
}

/* .button > div > img {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
} */

.button[data-disabled='true']:hover {
  transition: initial !important;
  cursor: default !important;
  opacity: initial !important;
  transform: initial !important;
}

.button.darken-hover:hover {
  transition: 0.3s;
  cursor: pointer;
  opacity: 0.7;
  transform: scale(1);
}

.button img {
  margin-right: 30px;
}

.button:hover {
  transition: 0.3s;
  cursor: pointer;
  transform: scale(1.05);
}

.button:focus {
  outline: none;
}

.textLight {
  color: white;
}

.button[data-disabled='true'] .disabled {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white !important;
  margin: 0;
}

.button[data-disabled='false'] .disabled {
  display: none;
}
