@media screen and (min-width: 1280px) and (max-width: 1440px) {
  :global(.liveshow-close.vjs-dismiss-btn-wrappers.relative) {
    top: 32% !important;
  }
  .lastJoinedNotif {
    top: 550px !important;
  }
}

:global(.shaka-range-element) {
  height: 100% !important;
  top: 0 !important;
}
:global(.video-js) {
  position: static;
  font-size: 16px;
  color: #979797;
  --main-color: #f49832;
  --secondary-color: #979797;
}

:global(.video-js-relative) {
  position: relative;
}

:global(.live-player .video-js .vjs-progress-control) {
  display: none;
}

:global(.live-player .video-js .vjs-current-time) {
  display: none;
}

:global(.live-player.relative) {
  height: 100% !important;
}

:global(.video-js .vjs-tech) {
  outline: none !important;
}

:global(.video-js .vjs-big-play-button) {
  background-color: var(--main-color);
  border: 3px solid #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: #fff;
  outline: none !important;
}

:global(.video-js:hover .vjs-big-play-button),
:global(.video-js .vjs-big-play-button:focus) {
  background-color: var(--main-color);
}

:global(.video-js .vjs-control) {
  color: #fff;
}
:global(.video-js .vjs-control-bar .vjs-button) {
  width: 55px;
  outline: none !important;
}
:global(.video-js .vjs-control-bar) {
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(0, 0, 0, 0.4) 90%,
    rgba(0, 0, 0, 0.4) 100%
  );
  padding: 0 12px;
}
/* custom svg icon */
:global(.video-js .vjs-control-bar .vjs-custom-icon) {
  cursor: pointer;
}
:global(.video-js .vjs-progress-control) {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
  top: -20px;
  border-radius: 1em;
  transition:
    top 150ms linear,
    opacity 150ms linear,
    transform 150ms linear;
  opacity: 0;
  padding: 0 4px;
}
:global(.video-js:hover .vjs-control-bar .vjs-progress-control) {
  opacity: 1;
  top: -22px;
}
:global(.video-js .vjs-progress-control:hover .vjs-progress-holder) {
  font-size: 25px;
}
:global(.vjs-icon-play:before),
:global(.video-js .vjs-play-control .vjs-icon-placeholder:before),
:global(.video-js .vjs-big-play-button .vjs-icon-placeholder:before) {
  content: '\f101';
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
}
/* end or replay icon */
:global(.vjs-icon-replay:before),
:global(.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before) {
  font-size: 35px;
}

/* show currentTime / duration */
:global(.video-js .vjs-time-control) {
  padding: 0;
  text-align: left;
  display: block;
}
:global(.video-js .vjs-time-control .vjs-current-time-display) {
  margin-right: 6px;
  padding-left: 4px;
}
:global(.video-js .vjs-time-control.vjs-time-divider) {
  min-width: 10px;
  color: #fff;
}
:global(.video-js .vjs-time-control.vjs-duration) {
  flex: 1;
}

/* hide remaining time */
:global(.video-js .vjs-remaining-time) {
  display: none;
}

/* Slider */
:global(.video-js .vjs-slider-bar.vjs-play-progress) {
  color: var(--main-color);
  background: var(--main-color);
}
:global(.video-js .vjs-load-progress div) {
  background: var(--secondary-color);
}

/*loading*/
:global(.vjs-loading-spinner:before),
:global(.vjs-loading-spinner:after) {
  border-color: var(--main-color);
}

/* menu popup */
:global(.vjs-menu-button-popup .vjs-menu .vjs-menu-content) {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  font-size: 14px;
  z-index: 2;
}

:global(.vjs-menu li.vjs-selected),
:global(.vjs-menu li.vjs-selected:focus),
:global(.vjs-menu li.vjs-selected:hover),
:global(.js-focus-visible .vjs-menu li.vjs-selected:hover) {
  background-color: #f49832;
  color: #fff;
}

/* hide the captions settings item from the captions menu */
:global(.video-js .vjs-texttrack-settings) {
  display: none;
}

:global(.video-js .vjs-subs-caps-button .vjs-icon-placeholder:before) {
  content: '\f10c' !important;
}

:global(.video-js .vjs-resolution-button) {
  width: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.video-js .vjs-resolution-button-label) {
  font-size: 1em;
  line-height: 2.6rem;
  position: absolute;
  top: 2;
  right: 16px;
}

:global(.vjs-dismiss-player-icon) {
  position: absolute;
  top: 17px;
  left: 20px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  cursor: pointer;
  z-index: 10;
}

:global(.vjs-dismiss-btn-wrapper) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 1rem;
}

:global(.live-dismiss-btn-wrapper) {
  background-color: #3f3e3e;
  width: 3rem;
  height: 3rem;
  position: relative;
  z-index: 10;
  border-radius: 25px;
  float: left;

  margin: 5px;
}
:global(.vjs-dismiss-btn-wrapper:hover) {
  visibility: visible !important;
}

:global(.live-dismiss-btn-wrapper:hover) {
  visibility: visible !important;
}

:global(.vjs-dismiss-btn-wrappers) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 2.5rem;
}

:global(.vjs-dismiss-btn-wrapper.relative) {
  top: 12% !important;
}

:global(.vjs-dismiss-btn-wrappers.relative) {
  top: 27% !important;
}

:global(.vjs-dismiss-btn-wrapper) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 1rem;
}

:global(.vjs-dismiss-btn-wrappers) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 2.5rem;
}

:global(.vjs-dismiss-btn-wrapper.relative) {
  top: 12% !important;
}

:global(.vjs-dismiss-btn-wrappers.relative) {
  top: 27% !important;
}

:global(.vjs-viewer-player-icon) {
  position: absolute;
  top: 17px;
  left: 20px;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 8px;
  z-index: 1;
  background-size: auto;
  background-image: url(../../assets/user.svg);
  background-repeat: no-repeat;
}

:global(.vjs-viewer-btn-wrapper) {
  background-color: #3f3e3e;
  width: 5rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 20px;
  top: 12%;
  right: 28%;
}

:global(.live-viewer-btn-wrapper) {
  background-color: #3f3e3e;
  width: 5rem;
  height: 3rem;
  position: relative;
  z-index: 10;
  border-radius: 20px;
  float: right;
  margin: 5px;
}
:global(.vjs-viewer-btn-wrappers) {
  background-color: #3f3e3e;
  width: 5rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 20px;
  top: 27%;
  right: 28%;
}

:global(.vjs-viewer-btn-wrappers > span) {
  position: absolute;
  top: 0.9rem;
  left: 3rem;
}

:global(.vjs-viewer-btn-wrapper > span) {
  position: absolute;
  top: 0.9rem;
  left: 3rem;
  color: #fff;
}

:global(.live-viewer-btn-wrapper > span) {
  position: absolute;
  top: 0.9rem;
  left: 3rem;
  color: #fff;
}

.showResumePopup :global(.video-js) {
  opacity: 0.7;
  pointer-events: none;
}

.errorWrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}
.subscriptionError {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin: auto;
}
.subscriptionError > img {
  width: 300px;
  margin-top: 1rem;
}
.subscriptionError .subtitle {
  margin: 2.5rem 0;
  text-align: center;
}
.subscribeButton {
  position: relative;
  background: #17c247;
  color: #fff;
  border-radius: 5px;
  padding: 1rem 2.5rem;
  margin: 4rem 0 3rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  outline: none;
  border: none;
}
.redeemtion {
  color: #f49832;
  cursor: pointer;
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
.subscriptionList {
  width: min-content;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.subscriptionItem {
  background: #148d00;
  color: #fff;
  width: 21%;
  border-radius: 5px;
  margin: 40px 5px 0px 5px;
  padding: 8px;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;
  cursor: pointer;
}
.customMessageWrapper {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 2rem;
  border-radius: 10px;
  border: 1px grey solid;
  max-width: 400px;
}

.customMessageWrapper button {
  padding: 0.5rem;
  color: #ffff;
  background-color: #148d00;
  border-radius: 5px;
  border-style: none;
}

.bulletCommentDisplay {
  display: block;
  width: 500px;
  height: 130px;
  position: relative;
  float: right;
  margin-top: -4rem;
  top: 122px;
  z-index: 1;
}

.bulletContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border-radius: 20px;
  font-size: large;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.5);
}

.bulletContainer > span:nth-child(2) {
  margin-left: 0.5rem;
  font-size: medium;
  font-weight: 500;
}

.basicGiftDisplay {
  position: relative;
  z-index: 1;
  top: 70px;
  width: 500px;
  text-align: right;
}

.basicWrapper {
  background: orange;
  width: 400px;
  float: right;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  height: 50px;
  margin-bottom: 2rem;
}

.basicWrapper > span {
  margin-left: 1rem;
}

.basicAvatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.basicIcon {
  position: absolute;
  float: right;
  left: 408px;
  width: 150px;
  margin-top: -16px;
}

.basicAmount {
  display: flex;
  width: 23rem;
  font-size: 100px;
  font-weight: 900;
  font-family: sans-serif;
  color: orange;
  position: absolute;
  left: 540px;
  margin-top: -20px;
  flex-wrap: nowrap;
}

.premiumGiftDisplay {
  padding-top: 2rem;
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 74%;
  display: flex;
  justify-content: center;
}

.premiumWrapper > img {
  margin-top: -250px;
}

.premiumInfo {
  background: orange;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 10rem;
  margin-left: 10rem;
  border-radius: 20px;
  padding: 0.5rem;
}

.lastJoinedNotif {
  position: absolute;
  background: #575859;
  z-index: 2;
  top: 750px;
  padding: 0.5rem 3rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  opacity: 0.8;
}

.lastJoinedNotif > span {
  font-size: small;
  text-decoration: solid;
  font-weight: 900;
}

.bulletWrapper {
  display: flex;
  float: right;
  flex-direction: column-reverse;
}

.bulletAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.bulletCommentData {
  color: orange !important;
  padding-left: 1rem;
}

.basicGiftWrapper {
  position: absolute;
  top: 280;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  height: 62vh;
  width: 900px;
  max-width: 900px;
  overflow: hidden;
}

.errorMessage {
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .subscriptionError {
    margin-top: 5rem;
  }
}
