:root {
  --color: #353535;
}
.section-container {
  padding: 10px 20px;
}

.top-section {
  background: #fff;
}

.section-info {
  display: flex;
  justify-content: space-between;
}

.section-name {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  color: var(--color);
}

.see-all {
  font-size: 15px;
  line-height: 20px;

  color: var(--color);
}

@media only screen and (max-width: 767px) {
  .section-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: var(--color);
  }
}
