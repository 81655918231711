:global(.glider-prev),
:global(.glider-next) {
  height: 220px; /* same as item's height */
  background: rgba(255, 255, 255, 0.7);
  width: 70px;
  top: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 36px;
}
:global(.glider-next) {
  right: -20px;
}
:global(.glider-prev:hover),
:global(.glider-next:hover) {
  color: #000;
}
:global(.glider-next:hover) {
  right: -18.5px;
}

:global(.glider::-webkit-scrollbar) {
  display: none;
}
@media screen and (max-width: 500px) {
  :global(.glider-prev),
  :global(.glider-next) {
    display: none;
  }

  .gliderItemTV {
    max-width: 50px;
  }
  :global(.live-tv .glider-slide) {
    min-width: 85px;
  }
}
