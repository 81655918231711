.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../assets/subscription_page_bg.svg');
}
.contentWrapper {
  z-index: 1;
  text-align: center;
}
.bottom-gradient {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.5) 50%,
    #ffffff 80%
  );
}

@media screen and (max-width: 320px) {
  .contentWrapper {
    width: 100% !important;
  }
}
