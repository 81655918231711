.title {
  text-align: center;
  font-size: 30px;
  z-index: 1;
}

.list-plan {
  display: flex;
  justify-content: center;
  z-index: 1;
  flex-wrap: wrap;
}
