.form-wrapper {
  margin: 2rem 0 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.form input {
  position: relative;
  padding: 24px 16px;
  background-color: #f5f5f5;
  border-radius: 6px;
  box-sizing: border-box;
}

.inputGroup {
  display: flex;
  width: 100%;
}

.showPasswordBtn {
  background-color: #f5f5f5 !important;
  color: #ffffff;
  font-weight: bold;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: none;
  cursor: pointer;
  transition: none !important;
  transform: none !important;
  height: 68px;
}

.showPasswordBtn > img {
  min-height: 18px;
  padding: 0.5rem;
}

.input-placeholder {
  position: absolute;
  left: 16px;
  margin-top: 5px;
  font-size: 10px;
  color: #747474;
  visibility: hidden;
}

.form input:focus {
  border: 1px solid var(--primary-color);
}

.form input:focus::placeholder {
  color: transparent;
}

.form input:focus ~ .input-placeholder {
  visibility: visible;
}

::-ms-reveal {
  filter: invert(100%);
}

.remind {
  color: var(--primary-color);
  margin: 1rem auto;
  cursor: pointer;
}

.remind[data-reset] {
  margin-bottom: 2rem;
}
