.wrapper {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 5px;
  display: block;
  background-color: white;
}

.section1 {
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 100% !important;
  margin-bottom: 0;
  background-color: #ed2027 !important;
  border: #ed2027 !important;
  color: #ffffff !important;
}
.btnClose {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  /*border-radius:8px;*/
  /*border:1px solid #ccc;*/
  width: 15px;
  height: 15px;
}
.logo {
  margin: auto 0 auto 0;
  width: 70px;
  height: 70px;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  font-weight: 600;
  margin: 0 5px 0 5px;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 0 5px 0 5px;
}

.contentContainer {
  display: inline-grid;
}
