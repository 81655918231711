:root {
  --color: #353535;
  --primary-color: #f7cc45;
  --secondary-color: #0b0b0b;
  --dark-color: #1c1c1c;
  --dark-modal-color: #121212;
  --gray-light-text-color: #c1c1c1;
  --grey-input-color: #747474;
  --light-input-color: #f9f9f9;
  --danger-color: #f20303;
  --success-color: #17c247;
  --container-contrast-color: #f5f5f5;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background: #fff;
  color: #000;
  -webkit-font-smoothing: antialiased;
}
.glider {
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  position: absolute;
}
body::-webkit-scrollbar-thumb {
  background: #282828;
  border: 1px solid #282828;
  border-radius: 4px;
}
ul,
li {
  box-sizing: border-box;
}
a {
  color: var(--color);
  text-decoration: none;
}
a:hover {
  color: #f49832;
  transition: 0.3s;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1 {
  font-size: 30px;
  line-height: 41px;
  margin: 35px 0 10px;
  font-weight: 300;
}

.remind {
  margin-top: 20px;
  font-size: 12px;
  line-height: 16px;
  color: white;
}
.remind > a {
  color: #f49832;
  margin-left: 5px;
}
.remind > a:hover {
  color: #f49832;
  opacity: 0.7;
  transition: 0.3s;
}

.error {
  color: red;
  font-size: 14px;
  text-align: center;
}

.success {
  color: green;
  font-size: 14px;
}

input {
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  background: white;
}
input:focus {
  outline: none;
}

.show-mobile {
  display: none !important;
}

.hide-mobile {
  display: inherit;
}

a {
  outline: none !important;
}

@media screen and (max-width: 920px) {
  .hide-mobile {
    display: none !important;
  }
  .show-mobile {
    display: inherit !important;
  }
}

/* css transition group animation style */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
  z-index: 2;
}
.fade-exit {
  opacity: 1;
  z-index: 2;
}
.fade-exit-active {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.fade-left-enter {
  opacity: 0;
  transform: translateX(-200px);
}

.fade-left-enter-active {
  transition: 0.4s;
  opacity: 1;
  transform: translateX(0);
}

.fade-left-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-left-exit-active {
  transition: 0.4s;
  opacity: 0;
  transform: translateX(-200px);
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 300ms,
    transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 300ms,
    transform 300ms;
}

.expand-rtl-enter {
  opacity: 0;
  width: 55px;
}

.expand-rtl-enter-active {
  opacity: 1;
  width: 290px;
  transition: all 0.4s;
}

.expand-rtl-exit {
  opacity: 1;
  width: 290px;
  transform: translateX(0);
}

.expand-rtl-exit-active {
  opacity: 0;
  width: 55px;
  transition: all 0.4s;
}

button:hover {
  transition: 0.3s;
  cursor: pointer;
  transform: scale(1.05);
}

button:disabled {
  background-color: #5c5c5c !important;
  border: none !important;
  color: #747474;
  pointer-events: none;
}

@media screen and (max-width: 400px) {
  .expand-rtl-enter-active {
    opacity: 1;
    width: 65vw;
    transition: all 0.4s;
  }

  .expand-rtl-exit-active {
    opacity: 0;
    width: 55px;
    transition: all 0.4s;
  }
}
