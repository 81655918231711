.shaka-text-container span {
  background-color: transparent;
  text-shadow:
    0 0 15px #000,
    -1px -1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    1px 1px 0 #000;
}
.shaka-nested-cue {
  background-color: transparent !important;
  font-size: 32px !important;
  text-align: center !important;
}

:root {
  --captionSize: 28px;
  --captionBgTransparant: 0%;
  --captionColor: 'white';
  --captionEdgesTextShadow: 0px 2px 2px rgba(0, 0, 0, 0.64);
  --captionLineHeight: 42px;
}
.shaka-text-container span.shaka-text-wrapper > span,
.shaka-text-container span.shaka-text-wrapper > span > span > span > span,
.shaka-text-container
  span.shaka-text-wrapper
  > span
  > span
  > span
  > span
  > span,
.shaka-text-container
  span.shaka-text-wrapper
  > span
  > span
  > span
  > span
  > span
  > span {
  color: var(--captionColor) !important;
  font-size: var(--captionSize) !important;
  background-color: rgba(0, 0, 0, var(--captionBgTransparant)) !important;
  line-height: var(--captionLineHeight) !important;
  text-shadow: var(--captionEdgesTextShadow) !important;
}

.shaka-text-container div > span {
  color: var(--captionColor) !important;
  font-size: var(--captionSize) !important;
  background-color: rgba(0, 0, 0, var(--captionBgTransparant)) !important;
  line-height: var(--captionLineHeight) !important;
  text-shadow: var(--captionEdgesTextShadow) !important;
}

@media only screen and (max-width: 600px) {
  .caption-mobile {
    display: none;
  }

  .shaka-text-container span.shaka-text-wrapper > span,
  .shaka-text-container span.shaka-text-wrapper > span > span,
  .shaka-text-container span.shaka-text-wrapper > span > span > span {
    color: var(--captionColor) !important;
    font-size: 14px !important;
    background: rgba(0, 0, 0, var(--captionBgTransparant)) !important;
    line-height: 21px !important;
    text-shadow: var(--captionEdgesTextShadow) !important;
  }
}
