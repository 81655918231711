.wrapper {
  max-width: 1308px;
  margin: 0 auto;
  min-height: calc(100vh - 350px);
}
.title-wrapper {
  display: flex;
  padding: 0 25px;
  margin-top: 25px;
}
.title-wrapper h1 {
  flex: 1;
  margin: 0 0 30px 0;
}

.content {
  line-height: 1.5;
  padding: 0 15px;
}

.content p {
  margin: 0;
}

@media screen and (max-width: 500px) {
  .title-wrapper {
    padding: 0 15px;
  }
  .title-wrapper h1 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    margin: 0 0 15px 0;
  }
  .content h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin: 0 0 15px 0;
  }
  .content p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .content p i {
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }
  .content ol p {
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .content ol li {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
  .content ol ol {
    margin-left: -40px;
  }

  .content ol ol li {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .content ol ol ol {
    margin-left: -30px;
  }
  .content ol p li {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }
}
