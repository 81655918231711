.wrapper {
  display: flex;
  justify-content: center;
}

.imgWrapper {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #353535;
  border-radius: 50%;
}

.imgWrapper img {
  width: 50%;
  height: 50%;
  filter: invert(1);
}

.featureName {
  margin-top: 5px;
  font-size: 11px;
  font-weight: bold;
  color: #353535;
}

.featuredItem {
  padding: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.featuredItem:hover {
  transition: 0.3s;
  transform: scale(1.1);
  cursor: pointer;
}

.featuredItem:last-child {
  margin-right: 0;
}
