.plan-wrapper {
  margin: 0 16px 20px 16px;
  width: 321px;
  /* height: 200px; */
}
.payment-logo {
  width: 40%;
  margin-bottom: 16px;
  margin-top: 24px;
  height: 24px;
}
.plan-info {
  background: #2e2e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 15px 10px 10px 10px;
  text-align: center;
  width: inherit;
  box-sizing: border-box;
  width: 321px;
  /* height: 200px; */
}
.plan-info p {
  margin: 0px 0px 8px 0px;
}
.plan-name {
  text-align: center;
  /* font-weight: 600;
  font-size: 15px; */
  margin: 0px 0px 16px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.plan-price {
  font-size: 20px;
  color: #f49832;
}
.plan-duration {
  font-size: 14px;
  line-height: 19px;
  color: #adacb4;
  margin-bottom: 8px;
}
.plan-info button {
  border: none;
  outline: none;
  width: 258px;
  height: 48px;
  /* font-size: 14px;
  font-weight: bold; */
  cursor: pointer;
  margin-top: 8px;
  background: #17c247 !important;
  border-radius: 6px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #f9f9f9;
  margin-bottom: 24px;
}
.plan-info button:hover {
  opacity: 0.9;
}
.plan-info button.popular {
  background-color: #23aa0d;
}
.plan-info button:disabled {
  background-color: #5c5c5c;
  color: #747474;
  pointer-events: none;
}
.popular {
  color: #23aa0d;
}
.plan-description {
  color: #f49832;
  font-size: 14px;
  line-height: 19px;
}

@media screen and (max-width: 500px) {
  .plan-wrapper {
    margin: 0 15px 20px 15px;
    width: 100%;
    min-width: 50%;
    /* height: 200px; */
  }
  .plan-info {
    width: 100%;
    padding: 0;
    border-radius: 10px;
    /* height: 200px; */
  }
  .plan-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
}

@media screen and (max-width: 320px) {
  .btn-purchase {
    width: auto !important;
    max-width: 300px !important;
    min-width: 200px !important;
  }
}
