.commentInput {
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 100%;
  bottom: 0;
  background-color: #f5f4f8;
  border-radius: 0 0 10px 10px;
  padding: 1rem;
  box-sizing: border-box;
}

.emoticonWrapper {
  width: 92%;
  display: flex;
  height: 3rem;
  justify-content: space-evenly;
}

.commentInput div > input {
  margin-bottom: 0px;
  border: none;
}

.emotToggle {
  background: url('../../assets/smile.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  padding-right: 10px;
  cursor: pointer;
}

.commentForm {
  display: flex;
  height: 3rem;
  padding: 0 10px;
  border-radius: 5rem;
  overflow: hidden;
  justify-content: space-evenly;
  background-color: #fff;
}

.commentForm h4 {
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 41px;
}

.emotInput {
  width: 100%;
  display: flex;
  height: 3rem;
  justify-content: space-evenly;
}

.emotList {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.emotList span {
  cursor: pointer;
}
