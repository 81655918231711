.wrapper {
  padding: 20px;
}

.cardTitle {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--color);
  margin-bottom: 15px;
}

.homeCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pictureWrapper {
  flex: 1;
}

.picture {
  width: 100%;
  /* padding-top: 75%; */
  border-radius: 10px;
  cursor: pointer;
  aspect-ratio: 16/9;
}

.picture:hover {
  opacity: 0.7;
  transition: 0.3s all;
}

.info {
  flex: 1;
  display: inline-flex;
  justify-content: center;
  padding: 0 0 0 30px;
  flex-direction: column;
}

.brief {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.brief > span {
  margin-right: 10px;
}

.description {
  margin-bottom: 10px;
}

.btn {
  background: rgb(46, 46, 46);
  color: #fff;
  border-radius: 5px;
  padding: 8px 60px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
}
.btn > span {
  position: relative;
}
.btn > span:before {
  position: absolute;
  top: calc(50% - 7px);
  left: -20px;
  border-radius: 1px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 12px solid #fff;
  content: '';
}

.subtitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
}

.title {
  font-weight: 300;
  font-size: 30px;
  margin-top: 5px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 500px) {
  .cardTitle {
    display: none;
  }

  .info {
    display: none;
  }
}
