.wrapper {
  margin: 0 5rem;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6.5rem 0 1rem;
}

.wrapper[data-mobile='true'] .title-wrapper {
  margin: 1rem 0 2rem;
}

.title-wrapper h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  margin: 0;
}

@media screen and (max-width: 900px) {
  .title-wrapper h1 {
    font-size: 30px;
  }
}

.wrapper[data-mobile='true'] .title-wrapper h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-color);
}

.language {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #121212;
  cursor: pointer;
}

.content-item ~ .content-item {
  margin-top: 2.5rem;
}

.content-title {
  color: var(--dark-modal-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.wrapper[data-mobile='true'] .content-title {
  color: var(--dark-modal-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.content-data {
  color: var(--dark-modal-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.wrapper[data-mobile='true'] .content-data {
  color: var(--dark-modal-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.content-data ul {
  margin-left: 2rem;
  list-style: initial;
}

.content-data ul li p {
  margin: 5px 0;
}

.content-data ol {
  padding-inline-start: 2rem;
}

.content-data p {
  margin: 1rem 0;
}

.content-data a {
  color: var(--dark-modal-color);
}

.content-data a:hover {
  color: var(--primary-color);
}

@media screen and (max-width: 700px) {
  .wrapper {
    margin: 0 1rem;
  }
}

@media screen and (max-width: 400px) {
  .title-wrapper {
    padding-top: 1rem;
    margin: 1rem 0 1rem;
    display: flex;
    flex-direction: column;
  }
}
