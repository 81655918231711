.wrapper {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 400px;
  min-width: 300px;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2px #000;
  z-index: 500;
}

.wrapper h3 {
  color: #f49832;
}

.wrapper span {
  margin-top: 0.5rem;
  color: #000;
  font-size: 14px;
}

.actions {
  margin: 1rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.actions > div {
  margin: 0 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #f49832;
  cursor: pointer;
}

.actions .allow {
  background-color: #f49832;
}

.notif-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 25%;
  max-width: 25vw;
  min-width: 200px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 500;
}

.notif-card {
  margin: 5px 10px;
  padding: 1rem;
  width: 100%;
  display: flex;
  background-color: #121212;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2px #000;
  z-index: 500;
}

.notif-card .card-thumbnail {
  width: 30%;
  object-fit: contain;
  cursor: pointer;
}

.card-detail {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: inherit;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.card-header > p {
  margin: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #f49832;
}

.card-header > img {
  width: 15px;
  align-self: flex-start;
  cursor: pointer;
}
.filter {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.card-detail > span {
  color: #dbdbdb;
  font-size: 14px;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  -webkit-box-orient: vertical;
  cursor: pointer;
}
