.wrapper {
  max-width: 1308px;
  margin: 0 auto;
}
.title-wrapper {
  display: flex;
  padding: 0 25px;
  margin-top: 10px;
}
.title-wrapper h1 {
  flex: 1;
  margin: 0 0 15px 0;
}
.title-wrapper p {
  cursor: pointer;
}
.collection {
  position: relative;
}
.list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.no-data {
  text-align: center;
}

@media screen and (max-width: 400px) {
  .wrapper h1 {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding-left: 0;
  }
}

:root {
  --color: #353535;
}
.section-container {
  padding: 20px 10px;
}

.top-section {
  background: #fff;
}

.section-info {
  display: flex;
  justify-content: space-between;
}

.section-name {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  color: var(--color);
}

.see-all {
  font-size: 15px;
  line-height: 20px;

  color: var(--color);
}

@media only screen and (max-width: 767px) {
  .section-container {
    padding: 20px 10px 0px 10px;
  }
  .section-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: var(--color);
  }
  .title-wrapper {
    display: none;
  }
  .no-data {
    margin: 20px 0px;
  }
}
