.media-info-wrapper {
  color: #000;
}
.general-info-wrapper {
  position: relative;
  min-height: 200px;
}

.gradient-top {
  position: absolute;
  width: 100%;
  height: 20%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(57, 57, 57, 0.14) 68.23%,
    rgba(149, 149, 149, 0) 100%
  );
  top: 0;
}

.thumbnail {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.media-metadata-wrapper {
  position: absolute;
  bottom: 20%;
  left: 75px;
  z-index: 1;
}

.media-title {
  font-weight: 300;
  font-size: 30px;
}

.media-metadata {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;
}

.media-metadata span {
  margin: 0 10px;
}

.media-metadata span:first-child {
  margin-left: 0;
}

.media-metadata > span + span {
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  padding-left: 1rem;
}

.media-metadata .rating {
  border: 1px solid #000;
  border-radius: 3px;
  padding: 0 6px;
}

.media-metadata-wrapper button {
  position: relative;
  background: #148d00;
  color: #fff;
  border-radius: 5px;
  padding: 8px 60px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  border: none;
  cursor: pointer;
}

.media-metadata-wrapper button.not-subscribed-button {
  background: #f49832;
}

.media-metadata-wrapper button:before {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 65px);
  border-radius: 1px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 12px solid #fff;
  content: '';
}

.description {
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  padding: 0 75px;
}

.gradient-bottom {
  position: absolute;
  width: 100%;
  height: 60%;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #ffffff 80%);
  bottom: 0;
}

.casting {
  padding: 0 75px;
  margin-bottom: 40px;
}
.casting h3 {
  margin: 15px 0 5px 0;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.btn-wrapper > img {
  margin-left: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.wrapper-btn-circle > img {
  padding-left: 15px;
}
@media screen and (max-width: 370px) {
  .btn-wrapper {
    align-items: stretch;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .wrapper-btn-circle {
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .media-metadata-wrapper {
    left: 45px;
  }
  .description {
    padding: 0 45px;
  }
  .casting {
    padding: 0 45px;
  }
}

@media screen and (max-width: 526px) {
  .media-metadata-wrapper {
    left: 0px;
  }
  .description {
    padding: 0 25px;
  }
  .casting {
    padding: 0 25px;
  }
}
@media screen and (max-width: 486px) {
  .thumbnail {
    height: 250px;
    width: 100% !important;
    position: relative !important;
    border-radius: 20px !important;
  }
  .general-info-wrapper {
    padding: 15px;
  }
  .media-metadata-wrapper {
    position: relative;
    bottom: auto;
  }
  .media-metadata-wrapper button {
    width: 100%;
  }
  .wrapper-btn-circle {
    display: inline-flex;
  }
  .media-metadata-wrapper button.not-subscribed-button {
    background: #f6cb52 !important;
    color: #000000;
  }
  .media-metadata-wrapper button:before {
    border-left: 12px solid #fff;
  }
  .media-metadata-wrapper button.not-subscribed-button:before {
    border-left: 12px solid #000;
  }
  .media-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-top: 5px;
  }
  .media-metadata {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .media-metadata p {
    margin: 0 6px 0 0;
  }
  .description {
    margin-top: 5px;
  }
  .casting {
    font-size: 14px;
  }
  .casting h3 {
    font-size: 14px;
  }
}
