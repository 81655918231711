.header {
  display: flex;
  padding: 15px 25px;
  align-items: center;
  background-color: #f6cb52;
}
.logo {
  width: 121px;
  height: 35px;
}
.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 140%;
  left: 53px;
}

.error {
  border: 1px solid red;
}
.menu {
  margin-left: 30px;
  display: flex;
  align-items: center;
}
.menu li {
  margin-right: 30px;
}
.menu li.selected a {
  color: #ffff;
  font-weight: bolder;
}
.sub-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sub-menu li {
  margin-left: 20px;
  cursor: pointer;
}
.sub-menu li:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.search {
  position: relative;
}
.search.search-opened:hover {
  opacity: 1;
}
.search .search-img {
  position: absolute;
  right: 0;
  top: -8px;
  opacity: 1;
  transition: 0.25s;
}
.search.search-opened .search-img {
  opacity: 0;
  transition: 0.25s;
}
.profile-menu-wrapper {
  display: none;
}
.profile-menu-wrapper.open {
  display: block;
}
.header img {
  vertical-align: middle;
}
.side-menu-toggle {
  cursor: pointer;
  margin-right: 10px;
}
.side-menu-toggle:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.search-form {
  position: absolute;
  top: -16px;
  right: 0;
}
.search-media-icon {
  position: absolute;
  left: 10px;
  top: 8px;
  width: 20px;
  height: 20px;
  background: url('../../assets/search_icon_active.svg') no-repeat center;
}
.search-media-field {
  background: #ffffff;
  border: 2px solid #f49832;
  border-radius: 18px;
  height: 35px;
  margin: 0;
  padding-left: 35px;
}

:global(.expand-rtl-enter-done) .search-media-field {
  width: 275px;
}
.avatar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}
.user-profile-icon {
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 15px;
  }
  .sub-menu li {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  :global(.expand-rtl-enter-done) .search-media-field {
    width: 65vw;
  }
}

.bottomBar {
  position: fixed;
  display: flex;
  width: 100vw;
  bottom: 0;
  height: 67px;
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 10;
}

.menuIcon {
  float: left;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-block: 1vh;
  margin-left: 0px;
  width: 25%;
  font-size: 14px;
}

.menuIcon div a {
  display: block;
}
.menuIcon div.selected a {
  color: #f7cc45;
}

.menuIcon > img {
  height: 2.5vh;
  padding-block: 0.5vh;
  color: #000000;
}

.menuIcon > img.selected {
  filter: invert(86%) sepia(78%) saturate(1304%) hue-rotate(0deg)
    brightness(104%) contrast(93%);
}

.menu {
  margin-left: 30px;
  display: flex;
  align-items: center;
  height: 100%;
}
.menu li {
  margin-right: 30px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}
.menu li a {
  height: 100%;
  display: flex;
  align-items: center;
}
.menu li.selected {
  border-bottom: 2px solid var(--primary-color);
}
.menu li span {
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.menu li span:hover {
  color: var(--primary-color);
}
.menu li span > img[data-dropdown] {
  margin-left: 8px;
  width: 5px;
  transform: rotate(-90deg);
}
