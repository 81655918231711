:root {
  --main-color: #f49832;
  --popup-width: 380px;
  --popup-height: 180px;
}
.wrapper {
  z-index: 10;
  box-sizing: border-box;
  background: #2e2e2e;
  border-radius: 6px;
  padding: 30px;
  width: 380px;
  height: 180px;
  text-align: center;
  position: absolute;
  top: calc(50% - (var(--popup-height) / 2));
  left: calc(50% - (var(--popup-width) / 2));
}
.wrapper p {
  color: #fff;
}
.header {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: var(--main-color);
}
.action button {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  margin-top: 5px;
  color: #fff;
  font-weight: bold;
  outline: none;
}
.resume {
  background: var(--main-color);
  border: none;
  margin-right: 10px;
}
.startOver {
  background: transparent;
  border: 1px solid var(--main-color);
  margin-left: 10px;
}
