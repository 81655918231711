.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3em;
  border-bottom: 1px solid #e0e0e0;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 2rem;
  overflow-y: auto;
}

.navigator {
  display: flex;
  flex-direction: row;
}

.navigator li a span {
  font-size: 14px;
  font-weight: normal;
  justify-content: center;
}

.navigator li {
  flex-basis: 20%;
  min-width: 100px;
  height: 3em;
  cursor: pointer;
  text-align: center;
}

.rightOption {
  display: flex;
  height: 3em;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
}

.verticalLine {
  border: 1px solid #545454;
  height: 20px;
}
