.slick-track {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
}

.slick-slide .content {
  display: none;
}

.content {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: left;
  color: #000;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.content.big {
  max-width: 100%;
}

.content.big .watch {
  font-size: 22px;
  line-height: 27px;
}

.content.big .title {
  font-size: 30px;
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.content.big .description {
  font-size: 15px;
  line-height: 21px;
}

.slick-center {
  transform: scale(1.4);
  transition: 0.5s;
  z-index: 1;
  filter: none !important;
}

.slick-center .content {
  display: block !important;
}

.slick-center .carousel-item {
  border-radius: 20px !important;
  cursor: pointer;
}
.carousel-item {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.carousel-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  background: rgba(255, 255, 255, 0.3);
}
.carousel-item.only {
  cursor: pointer;
}
.carousel-item.only:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(92, 92, 92, 0) 0%,
    rgba(225, 225, 225, 0.552083) 60.42%,
    #ffffff 100%
  );
}

.slick-center .carousel-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(92, 92, 92, 0) 0%,
    rgba(225, 225, 225, 0.552083) 60.42%,
    #ffffff 100%
  );
}

.watch {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.title {
  font-weight: 300;
  font-size: 22px;
  line-height: 22px;
  margin-top: 5px;
  margin-bottom: 12px;
}

.carousel-wrapper:focus {
  outline: none;
}

.description {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.description > span {
  margin-right: 10px;
}

.slick-dots {
  display: block;
  position: absolute;
  bottom: -20px;
}
.slick-dots li button:before {
  width: 10px;
  height: 10px;
  background-color: #f49832;
  border-radius: 50%;
  line-height: 12px;
  font-size: 8px;
  color: #f49832;
}

.slick-dots li button:after {
  content: ' ';
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}
.slick-dots li.slick-active button:after {
  background-color: #f49832;
}
.slick-dots li.slick-active button:before {
  color: #f49832;
  opacity: 1;
}

.dot {
  width: 10px;
  height: 10px;
  border: 1px solid #f49832;
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
}

.slick-next {
  right: 25px;
}

.slick-prev {
  left: 25px;
  z-index: 1;
}

.rating {
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 5px;
}

@media screen and (max-width: 764px) {
  .carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 0;
    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(0, 0, 0, 0.55) 50%,
      rgba(0, 0, 0, 1) 90%
    );
  }

  .slick-center .carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 0;
    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(0, 0, 0, 0.55) 50%,
      rgba(0, 0, 0, 1) 90%
    );
  }

  .carousel-wrapper {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .seeMoreButton {
    display: none;
  }

  .carousel-item {
    width: 100% !important;
    padding-top: 56.25% !important;
    position: relative !important;

    border-radius: 20px !important;
    cursor: pointer;
  }
  .slick-track {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 30px 0 !important;
  }
  .slick-slide .content {
    display: block;
    padding: 15px;
  }

  .slick-dots {
    bottom: 0;
  }

  .title {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
    margin-bottom: 4px;
  }

  .slick-center {
    transform: scale(1);
    transition: 0.5s;
    z-index: 1;
    filter: none !important;
    padding: 0 5px 0 5px;
  }

  .slick-center .content {
    display: block !important;
  }

  .slick-center .carousel-item {
    border-radius: 20px !important;
    cursor: pointer;
  }

  .rating {
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 5px;
  }
  .content {
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: left;
    color: #fff;
    padding: 15px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
  }
  .watch {
    font-size: 12px;
    line-height: 16px;
  }
  .title {
    font-size: 18px;
    line-height: 28px;
  }
  .description {
    font-size: 8px;
    line-height: 10px;
  }
}

.carousel-container {
  margin-bottom: 25px;
}
